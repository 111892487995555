.map-details {
  position: relative;
}

.details-container {
  position: absolute;
  margin-top: .5em;
  width: 90%;
  margin-left: 2.5em;
  margin-right: 2.5em;
  z-index: 9;
}
